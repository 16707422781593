import avatar from '../src/assets/avatar.jpg'



// CONTROL full website look from this point
export const MAIN_COLOR = '#325ea8'
export const SECONDARY_COLOR = 'white'
export const HOVER_COLOR = '#2979ff'
export const MOBILE = '+91-9773926687'
export const AVATAR_IMAGE = avatar
export const FONT_FAMILY = '"Times New Roman", Times, serif'
